import type GroupedMapPinDto from "@/dto/GroupedMapPinDto.ts";
import ApiClient from "@/api/infrastructure/ApiClient";

export default {
  async mapPins(): Promise<GroupedMapPinDto[]> {
    const response =
      await ApiClient.getJson<GroupedMapPinDto[]>("home/mapPins");

    return response.data;
  },
};
