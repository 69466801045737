<script setup lang="ts">
import { computed, ref } from "vue";
import logoPlaceholder from "@/assets/logo-placeholder.svg";

export interface Props {
  src?: string | null;
  alt: string;
  allowPlaceholder?: boolean;
  placeholder?: string;
}

const props = withDefaults(defineProps<Props>(), {
  allowPlaceholder: true,
  placeholder: logoPlaceholder,
});

const errored = ref(false);

const effectiveSource = computed(() => {
  if (props.allowPlaceholder) {
    if (errored.value || typeof props.src === "undefined" || props.src === null)
      return props.placeholder;
  }

  return props.src || undefined;
});
</script>

<template>
  <img
		loading="lazy"
    v-bind="$attrs"
    :src="effectiveSource"
    :alt="alt"
    @error="errored = true"
  />
</template>
