<script setup lang="ts">
import type MenuItem from "@/components/models/MenuItem";
import LanguageSelection from "@/components/LanguageSelection.vue";
import useAuthStore from "@/stores/auth";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import Role, { canEditAnyEntry } from "@/stores/interfaces/Role";
import { useRoute, useRouter } from "vue-router";
import KaraTextInput from "@/components/KaraTextInput.vue";
import { useSearchStore } from "@/stores/search";
import KaraIconButton from "@/components/KaraIconButton.vue";
import query from "@/helpers/query";

const auth = useAuthStore();
const i18n = useI18n();
const route = useRoute();
const router = useRouter();

const search = useSearchStore();

const hideDefaultElements = computed(() => {
  return (
    route.name === "welcome" ||
    (route.name as string | null)?.startsWith("onboarding")
  );
});

const menuItemsLeft = computed<MenuItem[]>(() => {
  const items: MenuItem[] = [];

  if (!hideDefaultElements.value) {
    items.push(
      {
        label: i18n.t("home"),
        to: {
          name: "home",
        },
      },
      {
        label: i18n.t("browse"),
        to: {
          name: "search",
        },
      },
    );
  }

  return items;
});

const menuItemsRight = computed<MenuItem[]>(() => {
  const items: MenuItem[] = [];
  const currentReturnQuery = query.calculateReturn(route);

  if (auth.isSignedIn && auth.user.roleId !== null) {
    if (auth.user.roleId === Role.Actor) {
      if (auth.user.hasActiveEntry) {
        items.push({
          label: i18n.t("my-entry"),
          to: {
            name: "entry-view",
            params: {
              entrySlug: auth.user.activeSlug,
            },
          },
        });
      } else if (auth.user.hasDraftEntry) {
        items.push({
          label: i18n.t("my-draft-entry"),
          to: {
            name: "entry-editor",
            params: {
              entrySlug: auth.user.draftSlug,
            },
          },
        });
      }
    } else if (canEditAnyEntry(auth.user.roleId)) {
      items.push({
        label: i18n.t("review"),
        to: {
          name: "admin-dashboard",
        },
      });
    }

    items.push(
      {
        label: i18n.t("account"),
        to: {
          name: "account",
        },
      },
      {
        label: i18n.t("logout"),
        to: {
          name: "logout",
          query: currentReturnQuery,
        },
      },
    );
  } else if (!auth.isSignedIn && !hideDefaultElements.value) {
    items.push({
      label: i18n.t("login"),
      to: {
        name: "login",
        query: currentReturnQuery,
      },
    });
  }

  return items;
});

async function doSearch(): Promise<void> {
  await router.isReady();

  router.push({
    name: "search",
    query: {
      q: search.parameters.query.trim(),
      ref: route.name === "home" ? "home" : undefined,
    },
  });
}
</script>

<template>
  <nav>
    <div class="nav-wrapper">
      <div class="navbar-left">
        <router-link
          class="logos"
          :to="{ name: 'home' }"
          tabindex="-1"
          :title="$t('home')"
        >
          <div :class="['logo dlr']"></div>
          <div :class="['logo kara', $i18n.locale]"></div>
        </router-link>
        <router-link
          v-for="link in menuItemsLeft"
          :key="link.label"
          :to="link.to"
        >
          {{ link.label }}
        </router-link>
      </div>
      <div class="search-bar" v-if="!hideDefaultElements">
        <form @submit.prevent="doSearch">
          <kara-text-input
            v-model="search.parameters.query"
            :placeholder="`${$t('search')}…`"
            type="search"
          />
          <kara-icon-button
            icon="search"
            type="submit"
            icon-size="1rem"
            variant="plain"
          />
        </form>
      </div>
      <div class="navbar-right">
        <router-link
          v-for="link in menuItemsRight"
          :key="link.label"
          :to="link.to"
        >
          {{ link.label }}
        </router-link>

        <language-selection />
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@use "sass:math";

@import "@/style/_content-wrapper.scss";

nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;

  height: calc(var(--navbar-height) + var(--navbar-backdrop-blur));
  margin-top: calc(-1 * var(--navbar-backdrop-blur));
  padding-top: var(--navbar-backdrop-blur);

  font-weight: bold;

  backdrop-filter: blur(var(--navbar-backdrop-blur));
  background: var(--navbar-background-color);
  border: 1px solid var(--navbar-border-color);

  box-shadow: var(--navbar-shadow);

  .nav-wrapper {
    @include ContentWrapper;
    --container-max-width: var(--navbar-max-width);

    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: calc(var(--utilities-base) * 2);

    .navbar-right {
      margin-left: auto;
      justify-content: flex-end;
    }

    .search-bar {
      flex-grow: 1;

      form {
        max-width: max(min(75%, 700px), 200px);
        display: flex;
        flex-direction: row;
        gap: calc(var(--utilities-base) * 1.5);
        margin-left: auto;
        margin-right: auto;

        input {
          width: 100%;
        }
      }
    }

    [class^="navbar-"] {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      & > * {
        --padding-y: 0.5rem;

        display: flex;
        align-items: stretch;

        line-height: calc(var(--navbar-height) - 2 * var(--padding-y));
        text-align: center;
        padding: var(--padding-y) 1rem;
        text-decoration: none;
        font-weight: normal;
        white-space: nowrap;

        /*noinspection CssUnusedSymbol*/
        &.router-link-active {
          text-decoration: underline;
        }
      }

      $heightFormula: calc(var(--navbar-height) - var(--navbar-padding));

      .kara {
        $enLogoAspectRatio: math.div(238, 241);
        $deLogoAspectRatio: math.div(332, 241);

        background-image: url("/kara-en-dynamic.min.svg");
        width: calc($heightFormula * $enLogoAspectRatio);

        height: $heightFormula;
        align-self: center;

        background-repeat: no-repeat;
        background-size: contain;

        /*noinspection CssUnusedSymbol*/
        &.de-DE {
          background-image: url("/kara-de-dynamic.min.svg");
          width: calc($heightFormula * $deLogoAspectRatio);
        }

        // check for Safari
        @supports (-webkit-hyphens: none) {
          // only provide a different url for safari, as it doesn't support dynamic SVGs
          @media (prefers-color-scheme: dark) {
            background-image: url(@/assets/logos/kara-en-inverted.min.svg);

            /*noinspection CssUnusedSymbol*/
            &.de {
              background-image: url(@/assets/logos/kara-de-inverted.min.svg);
            }
          }
        }
      }

      .dlr {
        $dlrLogoAspectRatio: math.div(90, 70);

        background-image: url(@/assets/logos/dlr-logo-dynamic.svg);
        width: calc($heightFormula * $dlrLogoAspectRatio);

        height: $heightFormula;
        align-self: center;

        background-repeat: no-repeat;
        background-size: contain;

        // check for Safari
        @supports (-webkit-hyphens: none) {
          // only provide a different url for safari, as it doesn't support dynamic SVGs
          @media (prefers-color-scheme: dark) {
            background-image: url(@/assets/logos/dlr-logo-inverted.svg);
          }
        }
      }
    }

    .navbar-left {
      .logos {
        gap: calc(var(--utilities-base) * 3);
      }
    }

    .navbar-right {
      margin-left: auto;
      justify-content: flex-end;
    }

    @media print {
      display: none;
    }
  }
}
</style>
