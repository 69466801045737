<script setup lang="ts">
import { RouterView } from "vue-router";
import KaraNavbar from "@/components/KaraNavbar.vue";
import KaraBanner from "@/components/KaraBanner.vue";
import KaraFooter from "@/components/KaraFooter.vue";
import { ModalsContainer } from "vue-final-modal";
import useAuthStore from "@/stores/auth";
import { onMounted } from "vue";
import { useSeoMeta } from "@unhead/vue";
import { useI18n } from "vue-i18n";
import logoDe from "@/assets/logos/kara-de.min.svg";
import logoEn from "@/assets/logos/kara-en.min.svg";

const auth = useAuthStore();
const i18n = useI18n();

useSeoMeta({
  titleTemplate: (t) => {
    const siteName = i18n.t("catalogue-german-space-actors");
    if (typeof t === "undefined" || t === "") return siteName;

    return `${t} | ${siteName}`;
  },
  ogSiteName: () => i18n.t("catalogue-german-space-actors"),
  ogImage: getImageUrl,

  // special tags for twitter
  twitterTitle: () => i18n.t("catalogue-german-space-actors"),
  twitterImage: getImageUrl,
  twitterCard: "summary",
});

onMounted(async () => await auth.fetchSelf());

function getImageUrl() {
  return i18n.locale.value === "de-DE" ? logoDe : logoEn;
}
</script>

<template>
  <kara-banner />
  <kara-navbar />
  <RouterView
      style="min-height: calc(100vh - var(--navbar-height)); z-index: 0"
  />
  <kara-footer />
  <modals-container />
</template>
