<script setup lang="ts">
import { computed, ref } from "vue";
import type GroupedMapPinDto from "@/dto/GroupedMapPinDto.ts";
import KaraMapPinInfo from "@/components/KaraMapPinInfo.vue";
import type { MapType } from "@/components/models/MapType";
import {
  GoogleMap,
  AdvancedMarker,
  InfoWindow,
  MarkerCluster,
} from "vue3-google-map";
import DismissButton from "@/components/DismissButton.vue";
import * as clusterer from "@googlemaps/markerclusterer";

export interface Props {
  zoom?: number;
  latitude?: number;
  longitude?: number;
  pinCenter?: boolean;
  pins?: GroupedMapPinDto[];
  type: MapType;
}

const props = withDefaults(defineProps<Props>(), {
  zoom: 6,
  latitude: 51.16077,
  longitude: 10.448851,
  pinCenter: false,
});

const emit = defineEmits([
  "update:zoom",
  "update:latitude",
  "update:longitude",
]);

const center = computed({
  get: () => ({ lat: props.latitude, lng: props.longitude }),
  set: (center) => {
    emit("update:latitude", center.lat);
    emit("update:longitude", center.lng);
  },
});

const selectedPin = ref<GroupedMapPinDto | null>(null);

const selectedPinPosition = computed(() => {
  if (selectedPin.value === null) {
    return null;
  }

  return {
    lat: selectedPin.value.location.latitude,
    lng: selectedPin.value.location.longitude,
  };
});

function openMarker(pin: GroupedMapPinDto | null) {
  if (pin === null) {
    selectedPin.value = null;

    return;
  }

  selectedPin.value = pin;
}

const bodyStyles = window.getComputedStyle(document.body);

const markerColor = bodyStyles.getPropertyValue("--primary");
const backgroundColor = bodyStyles.getPropertyValue("--color-paper");
const markerBorderColor = bodyStyles.getPropertyValue("--paper-white");
const smallClusterColor = bodyStyles.getPropertyValue("--primary");
const bigClusterColor = bodyStyles.getPropertyValue("--color-danger");

function renderCluster(
  cluster: clusterer.Cluster,
  stats: clusterer.ClusterStats,
  map: google.maps.Map,
): google.maps.marker.AdvancedMarkerElement {
  const count = cluster.count;

  const color =
    count > Math.max(10, stats.clusters.markers.mean)
      ? bigClusterColor
      : smallClusterColor;

  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("fill", color);
  svg.setAttribute("viewBox", "0 0 240 240");
  svg.setAttribute("width", "45");
  svg.setAttribute("height", "45");
  svg.innerHTML = `
<circle cx="120" cy="120" opacity=".6" r="70" />
<circle cx="120" cy="120" opacity=".3" r="90" />
<circle cx="120" cy="120" opacity=".2" r="110" />
<circle cx="120" cy="120" opacity=".1" r="130" />
<text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="75" dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
`;

  // create marker using svg icon
  return new google.maps.marker.AdvancedMarkerElement({
    position: cluster.position,
    content: svg,
    map: map,
    zIndex: 1000 + count,
  });
}

const mapsApiKey = import.meta.env.VITE_MAPS_API_KEY as string | undefined;
if (typeof mapsApiKey === "undefined") {
  alert("VITE_MAPS_API_KEY is missing in .env!");
}
</script>

<template>
  <google-map
    class="kara-map"
    map-id="KaraMap"
    :street-view-control="false"
    map-type-id="hybrid"
    :api-key="mapsApiKey"
    :center="center"
    :zoom="zoom ?? 7"
    :background-color="backgroundColor"
    @click="openMarker(null)"
  >
    <advanced-marker v-if="pinCenter" :options="{ position: center }" />

    <marker-cluster
      @click="openMarker(null)"
      :options="
        {
          renderer: { render: renderCluster } as clusterer.Renderer,
        } as clusterer.MarkerClustererOptions
      "
      v-if="pins"
    >
      <advanced-marker
        ref="marker"
        v-for="(pin, idx) in pins"
        :key="idx"
        @click="openMarker(pin)"
        :options="{
          position: {
            lat: pin.location.latitude,
            lng: pin.location.longitude,
          },
        }"
        :pin-options="{
          background: markerColor,
          borderColor: markerBorderColor,
          glyphColor: markerBorderColor,
          scale: 0.9,
        }"
      />
    </marker-cluster>

    <info-window
      :model-value="selectedPin !== null"
      @closeclick="openMarker(null)"
      :options="{
        headerDisabled: true,
        position: selectedPinPosition,
        pixelOffset: { width: 0, height: -30 },
      }"
    >
      <dismiss-button @dismissed="openMarker(null)" />
      <kara-map-pin-info v-if="selectedPin !== null" :pin="selectedPin" />
    </info-window>
  </google-map>
</template>

<style scoped lang="scss">
.kara-map {
  width: 100%;
  height: 100%;
}
</style>
